import Login from "@/components/Login";

new Vue({
	el: "#login-vue",
	name: "LoginVue",
	components: {
		"form-login": Login,
	},
	data() {
		return {
			logo: "",
			reCAPTCHA_site_key: "",
			isProduction: false,
			errors: [],
			siteKey: '',
			useTurnstile: false,
		};
	},
	mounted() {
		this.initializeVariable();
	},
});
