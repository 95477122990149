var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-8 col-lg-6 col-xl-5" }, [
        _c("div", { staticClass: "card loginCardDiv" }, [
          _c(
            "div",
            { staticClass: "card-body p-4" },
            [
              _c("div", { staticClass: "text-center w-75 m-auto" }, [
                _c("span", [
                  _c("img", {
                    staticStyle: { "max-height": "70px" },
                    attrs: { src: _vm.logoLogin, alt: "" }
                  })
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-muted mb-4 mt-3" }, [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tDigite seu endereço de e-mail ou NIF e senha para aceder o painel de administração.\n\t\t\t\t\t\t"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("grouped-errors", { attrs: { errors: _vm.errors } }),
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.login.apply(null, arguments)
                    },
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "form-group mb-3" }, [
                    _c("label", { attrs: { for: "usernameAccess" } }, [
                      _vm._v("E-mail")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.username,
                          expression: "form.username"
                        }
                      ],
                      ref: "username",
                      staticClass: "form-control",
                      attrs: {
                        id: "usernameAccess",
                        name: "username",
                        type: "text",
                        placeholder: "Digite o e-mail de acesso",
                        required: "",
                        autocomplete: "username",
                        autofocus: ""
                      },
                      domProps: { value: _vm.form.username },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "username", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _vm.url
                    ? [
                        _c("div", { staticClass: "form-group mb-3" }, [
                          _c("label", { attrs: { for: "password" } }, [
                            _vm._v("Password")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.password,
                                expression: "form.password"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "password",
                              type: "password",
                              name: "password",
                              required: "",
                              autocomplete: "current-password",
                              placeholder: "Enter your password"
                            },
                            domProps: { value: _vm.form.password },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "password",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group mb-3" }, [
                          _c(
                            "div",
                            { staticClass: "custom-control custom-checkbox" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.remember,
                                    expression: "form.remember"
                                  }
                                ],
                                staticClass: "custom-control-input",
                                attrs: {
                                  id: "remember",
                                  type: "checkbox",
                                  name: "remember"
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.form.remember)
                                    ? _vm._i(_vm.form.remember, null) > -1
                                    : _vm.form.remember
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.form.remember,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.form,
                                            "remember",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.form,
                                            "remember",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.form, "remember", $$c)
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-control-label",
                                  attrs: { for: "remember" }
                                },
                                [_vm._v(" Manter conectado ")]
                              )
                            ]
                          )
                        ])
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group mb-0 text-center" }, [
                    _vm.processSubmitted
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-dark btn-block",
                            attrs: { type: "button", disabled: "" }
                          },
                          [
                            _c("span", {
                              staticClass:
                                "spinner-border spinner-border-sm mr-1",
                              attrs: { role: "status", "aria-hidden": "true" }
                            }),
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\tProcessando...\n\t\t\t\t\t\t\t"
                            )
                          ]
                        )
                      : _c(
                          "button",
                          {
                            ref: "btnSubmit",
                            staticClass: "btn btn-dark btn-block",
                            attrs: { type: "button" },
                            on: { click: _vm.login }
                          },
                          [_vm._v("\n\t\t\t\t\t\t\t\tAceder\n\t\t\t\t\t\t\t")]
                        )
                  ]),
                  _vm._v(" "),
                  _vm.useTurnstile
                    ? [
                        _c("Turnstile", {
                          attrs: {
                            "site-key": _vm.siteKey,
                            appearance: "interaction-only",
                            placeholder: false
                          },
                          on: { verify: _vm.setCaptcha, error: _vm.onError },
                          model: {
                            value: _vm.form.cfResponse,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "cfResponse", $$v)
                            },
                            expression: "form.cfResponse"
                          }
                        })
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-3" }, [
          _c("div", { staticClass: "col-12 text-center" }, [
            _c("p", [
              _c(
                "a",
                {
                  staticClass: "text-light-grey ml-1",
                  attrs: { href: _vm.vueRoute("password.request") }
                },
                [_vm._v("\n\t\t\t\t\t\t\tForgot your password?\n\t\t\t\t\t\t")]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }