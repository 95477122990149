<template>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-md-8 col-lg-6 col-xl-5">
				<div class="card loginCardDiv">
					<div class="card-body p-4">
						<div class="text-center w-75 m-auto">
							<span>
								<img
									:src="logoLogin"
									style="max-height: 70px"
									alt=""
								>
							</span>
							<p class="text-muted mb-4 mt-3">
								Digite seu endereço de e-mail ou NIF e senha para aceder o painel de administração.
							</p>
						</div>
						<grouped-errors :errors="errors" />

						<form @keyup.prevent.enter="login" @submit.prevent>
							<div class="form-group mb-3">
								<label for="usernameAccess">E-mail</label>
								<input
									id="usernameAccess"
									ref="username"
									v-model="form.username"
									name="username"
									class="form-control"
									type="text"
									placeholder="Digite o e-mail de acesso"
									required
									autocomplete="username"
									autofocus
								>
							</div>

							<template v-if="url">
								<div class="form-group mb-3">
									<label for="password" class="">Password</label>

									<input
										id="password"
										v-model="form.password"
										type="password"
										class="form-control"
										name="password"
										required
										autocomplete="current-password"
										placeholder="Enter your password"
									>
								</div>

								<div class="form-group mb-3">
									<div class="custom-control custom-checkbox">
										<input
											id="remember"
											v-model="form.remember"
											class="custom-control-input"
											type="checkbox"
											name="remember"
										>
										<label class="custom-control-label" for="remember"> Manter conectado </label>
									</div>
								</div>
							</template>
							<div v-cloak class="form-group mb-0 text-center">
								<button
									v-if="processSubmitted"
									class="btn btn-dark btn-block"
									type="button"
									disabled
								>
									<span
										class="spinner-border spinner-border-sm mr-1"
										role="status"
										aria-hidden="true"
									/>
									Processando...
								</button>

								<button
									v-else
									ref="btnSubmit"
									class="btn btn-dark btn-block"
									type="button"
									@click="login"
								>
									Aceder
								</button>
							</div>
							<template v-if="useTurnstile">
								<Turnstile
									v-model="form.cfResponse"
									:site-key="siteKey"
									appearance="interaction-only"
									:placeholder="false"
									@verify="setCaptcha"
									@error="onError"
								/>
							</template>
						</form>
					</div>
					<!-- end card-body -->
				</div>

				<div class="row mt-3">
					<div class="col-12 text-center">
						<p>
							<a
								:href="vueRoute('password.request')"
								class="text-light-grey ml-1"
							>
								Forgot your password?
							</a>
						</p>
					</div>
					<!-- end col -->
				</div>
			</div>
			<!-- end col -->
		</div>
	</div>
</template>

<script>

export default {
	name: "Login",
	components: {
		Turnstile: () => import("@/components/Turnstile.vue"),
	},
	props: {
		logoLogin: {
			type: String,
			required: true,
		},
		recaptchaKey: {
			type: String,
			required: true,
		},
		isProduction: {
			type: Boolean,
			required: false,
			default: false,
		},
		initialErrors: {
			type: Array,
			required: false,
			default: () => [],
		},
		siteKey: {
			type: String,
			required: false,
			default: '',
		},
		useTurnstile: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			form: {
				username: undefined,
				password: undefined,
				remember: undefined,
				"g-recaptcha-response": undefined,
				cfResponse: null,
			},
			url: null,
			processSubmitted: false,
			errors: [],
		};
	},
	mounted() {
		setTimeout(() => {
			this.errors = this.initialErrors;
		}, 500);
	},
	methods: {
		async login(event) {
			event.preventDefault();
			this.processSubmitted = true;
			this.errors = [];

			if (!this.form.username) {
				this.errors.push("É obrigatório a indicação de um valor para o campo email.");
				return this.inError();
			}

			this.$refs.username.disabled = true;

			if (this.url) {
				return this.processLoginDefault();
			}

			try {
				const { data } = await this.$http.post(route("verifyTypeLogin"), {
					email: this.form.username,
				});

				setTimeout(() => {
					if (data.strategy === "SSO") {
						window.location = data.url;
					} else {
						this.processSubmitted = false;
						this.url = data.url;

						this.$nextTick().then(() => {
							let elementPassword = document.getElementById("password");

							elementPassword.focus();
							elementPassword.select();

							// this.$refs.btnSubmit.$on('click', () => {
							// 	this.processLoginDefault();
							// })
						});
					}
				}, 1000);
			} catch (e) {
				this.processSubmitted = false;

				if (e && typeof e.response.status !== "undefined" && parseInt(e.response.status) === 422) {
					this.errors.push(e.response.data.message);
					return this.inError();
				}

				this.$refs.username.disabled = false;

				console.error(e);
			}
		},
		async processLoginDefault() {
			if (!this.form.password) {
				this.errors.push("Necessário indicar a password");
				return this.inError();
			}

			try {
				const { data } = await this.$http.post(this.url, this.form);
				if (data && data.hasOwnProperty("redirect")) {
					window.location = data.redirect;
				} else {
					this.errors = [
						"Erro ao tentar aceder a aplicação, por favor tente novamente.",
					];

					console.error('data', data)
				}
			} catch (e) {
				if (
					e.hasOwnProperty("response") &&
					e.response.hasOwnProperty("status") &&
					typeof e.response.status !== "undefined" &&
					parseInt(e.response.status) === 401
				) {
					this.errors.push(e.response.data.message || "Sem acesso a aplicação");
					return this.inError();
				}

				if (e.hasOwnProperty("response")) {
					this.errors = HelperJS.formatterErrorsLaravel(e);
					return this.inError();
				}

				return this.inError();
			}
		},
		setCaptcha({ response }) {
			this.form.cfResponse = response;
		},
		inError() {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
			this.processSubmitted = false;
			this.$refs.username.disabled = false;
			return false;
		},
		onError(e) {
			console.error('error render turnstile:', e)
		}
	},
};
</script>

<style scoped></style>
